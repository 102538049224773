import React from "react"
import styles from "./footer.module.css"

const Footer = () => {
  const today = new Date()
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.copyright}>
          ©{today.getFullYear()} Time2Client
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/time2client/"
            title="Linkedin"
            className={styles.link}
          >
            Linkedin
          </a>
        </div>
      </div>
      <div className={styles.googleDisclaimer}>
        Questo sito è protetto da reCAPTCHA di Google. Per saperne di più:{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> e{" "}
        <a href="https://policies.google.com/terms">Termini di Servizio</a>.
      </div>
    </div>
  )
}

export default Footer
