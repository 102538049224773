import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import styles from "./layout.module.css"

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={styles.main}>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
