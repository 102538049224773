import React from "react"
import styles from "./header.module.css"
import Logo from "../components/logo"

const Header = () => {
  return (
    <div className={styles.container}>
      <a href="https://time2client.com">
        <Logo />
      </a>
    </div>
  )
}

export default Header
